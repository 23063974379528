import {
  Chain as RainbowKitChain,
  getDefaultConfig,
  Wallet,
  WalletDetailsParams,
} from '@rainbow-me/rainbowkit';
import {
  argentWallet,
  braveWallet,
  coinbaseWallet,
  imTokenWallet,
  injectedWallet,
  ledgerWallet,
  metaMaskWallet,
  rabbyWallet,
  safeWallet,
  walletConnectWallet,
  zerionWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { cookieStorage, createConnector, createStorage } from 'wagmi';
import { safe } from 'wagmi/connectors';
import { getDefaultChains } from 'src/utils/emoney/chains';

const TEST_WALLETCONNECT_PROJECT_ID = 'e39e5a24285fe0a946cd3b6d9eada7af';
const PROD_WALLETCONNECT_PROJECT_ID = '9dfea1e28fa874dc0d8c95fb02d58886';

const appName = 'Monerium';
const appDescription =
  'Monerium is an Electronic Money Institution authorized to issue money on blockchains';

/** Custom Safe Wallet config to add shimDisconnect and allowedDomains */
const customSafeWallet = (): Wallet => ({
  ...safeWallet(),
  createConnector: (walletDetails: WalletDetailsParams) => {
    return createConnector((config) => ({
      ...safe({
        shimDisconnect: true,
        allowedDomains: [
          /app\.safe\.global$/,
          /^.*\.coinshift\.global$/,
          /localhost:5173$/,
        ],
      })(config),
      ...walletDetails,
    }));
  },
});

const chains = getDefaultChains();
const isSafe = customSafeWallet().installed;

const onlySafeWallet = [
  {
    groupName: 'Safe',
    wallets: [customSafeWallet],
  },
];

const wallets = isSafe
  ? onlySafeWallet
  : [
      {
        groupName: 'Recommended',
        wallets: [
          /**
           * There is a bug in RainbowKit that doesn't allow us to have multiple injected wallets.
           * metaMaskWallet is the only one that can handle all other injected wallets properly.
           * the injectedWallet fails in that regard.
           */
          // injectedWallet,
          // rabbyWallet,
          metaMaskWallet,
          // braveWallet,
          customSafeWallet,
          walletConnectWallet,
          ledgerWallet,
        ],
      },
      {
        groupName: 'Others',
        wallets: [coinbaseWallet, imTokenWallet, argentWallet],
      },
    ];

// eslint-disable-next-line import/prefer-default-export
export const config = (env: 'dev' | 'staging' | 'sandbox' | 'production') =>
  getDefaultConfig({
    appName,
    appDescription,
    appUrl: 'https://monerium.app',
    appIcon: 'https://monerium.app/icon.svg',
    projectId:
      env === 'production'
        ? PROD_WALLETCONNECT_PROJECT_ID
        : TEST_WALLETCONNECT_PROJECT_ID,
    chains: chains as unknown as readonly [
      RainbowKitChain,
      ...RainbowKitChain[],
    ],
    ssr: true,
    multiInjectedProviderDiscovery: false, // There is an issue with disconnecting from injected wallets when this is true
    storage: createStorage({
      storage: isSafe ? localStorage : cookieStorage,
    }),
    wallets,
  });
