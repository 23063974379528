import React, { FC, useMemo } from 'react';
import StyleContext from 'isomorphic-style-loader/StyleContext';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { SnackbarProvider } from 'notistack';
import { SWRConfig } from 'swr';
import { cookieToInitialState } from 'wagmi';
import Announcement from 'src/components/Alert/Announcement';
import { config as getWagmiConfig } from 'customer/components/emoney/Wallet/config';
import WalletConnector from './components/emoney/Wallet/WalletConnector';
import DataFetcher from './components/Layout/DataFetcher';
import AppContext from './context';
import { AppConfig } from './types';

const App: FC<{
  pathname: string;
  query?: Record<string, string>;
  config: AppConfig;
  insertCss: (...styles: IsomorphicStyleLoaderStyle[]) => void;
  children: React.ReactNode;
  cookies: string;
}> = ({ pathname, query, config, insertCss, children, cookies }) => {
  const appCtx = useMemo(
    () => ({ pathname, query, insertCss, ...config }),
    [pathname, query, config],
  );
  const styleCtx = useMemo(() => ({ insertCss }), [insertCss]);

  const wagmiConfig = getWagmiConfig(config.environment);
  const wagmiInitialState = cookieToInitialState(wagmiConfig, cookies);

  return (
    <StyleContext.Provider value={styleCtx}>
      <SWRConfig
        value={{
          fetcher: (url: string) => fetch(url).then((res) => res.json()),
        }}
      >
        <AppContext.Provider value={appCtx}>
          <WalletConnector
            config={wagmiConfig}
            initialState={wagmiInitialState}
          >
            <SnackbarProvider maxSnack={3}>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <Announcement
                  announcement={config.announcement}
                  link={config.announcementLink}
                />
                <DataFetcher>{children}</DataFetcher>
              </LocalizationProvider>
            </SnackbarProvider>
          </WalletConnector>
        </AppContext.Provider>
      </SWRConfig>
    </StyleContext.Provider>
  );
};

export default App;
