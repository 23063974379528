import history from 'utils/history';
import accountActions from 'src/customer/components/emoney/Account/actions';
import { AppThunkDispatch } from 'src/customer/types';
import { Chain } from 'src/types/emoney/Chain';
import { equal } from 'src/utils/address';
import { isSmartContract } from 'src/utils/emoney/wallet';
import ValidationError from 'src/utils/error';
import { Account } from '../Account/types';
import { isSafe } from './safe';

export const accountSelection =
  ({
    addressUpdate,
    chainUpdate,
    connectedAddress,
    connectedChain,
    selectedAccount,
    accounts,
    chains,
  }: {
    addressUpdate?: string;
    chainUpdate?: { id: number };
    connectedAddress?: string;
    connectedChain?: Chain;
    selectedAccount?: Account;
    accounts: Account[];
    chains: Chain[];
  }) =>
  (dispatch: AppThunkDispatch) => {
    const shouldRedirect =
      history?.location.pathname.includes('/accounts') ||
      history?.location.pathname.includes('/addresses/link') ||
      history?.location.pathname === '/';

    const addressToFind = addressUpdate || connectedAddress;
    const chainToFind =
      chains.find((c) => c.id === chainUpdate?.id) || connectedChain;

    const alreadySelected =
      selectedAccount?.chain === chainToFind?.moneriumId?.chain &&
      equal(selectedAccount?.address, addressToFind);
    const noChange =
      equal(addressUpdate, connectedAddress) &&
      chainUpdate?.id === connectedChain?.id;

    if (alreadySelected || noChange) {
      return;
    }

    const accountToSelect = accounts.find(
      (a: Account) =>
        equal(a.address, addressToFind) &&
        a.chain === chainToFind?.moneriumId?.chain &&
        (a.currency === selectedAccount?.currency || a.currency === 'eur') &&
        a.meta?.isVisible,
    );

    if (accountToSelect) {
      dispatch(accountActions.selectAccount(accountToSelect));
      if (shouldRedirect) {
        history?.push(`/accounts/${accountToSelect.id}`);
      }
    }

    if (!accountToSelect && addressToFind) {
      dispatch(accountActions.selectAccountReset());
      /** Check first if it's already linked */
      const linkedAccount = accounts.find((a: Account) =>
        equal(a.address, addressToFind),
      );
      /** Account is linked, but might be hidden */
      if (linkedAccount) {
        dispatch(accountActions.selectAccountReset());
        if (shouldRedirect) {
          history?.push(`/accounts`);
        }
        return;
      }

      /** Address needs to be linked */
      if (shouldRedirect) {
        if (addressToFind) {
          if (
            !history?.location.pathname.includes(
              `/addresses/link/${addressToFind}`,
            )
          ) {
            history?.push(`/addresses/link/${addressToFind}`);
          }
        }
      }
    }
  };

const isSafeOnChains = async (chains: Chain[], address: string) => {
  const results = await Promise.all(
    chains.map(async (chain) => {
      const isSafeAddress = await isSafe(address, chain.id);
      return isSafeAddress ? chain.id : null;
    }),
  );
  const safeChainIds = results.filter(Boolean);
  return safeChainIds;
};

const isSmartContractOnChains = async (chains: Chain[], address: string) => {
  const results = await Promise.all(
    chains.map(async (chain) => {
      const isSmartContractAddress = await isSmartContract(chain, address);
      return isSmartContractAddress ? chain.id : null;
    }),
  );

  const smartContractChainIds = results.filter(Boolean);

  return smartContractChainIds;
};

/*
 * 1. Returns 'safe' and a list of chain Ids if any of the chains have the address as a Safe
 * 2. Returns 'contract' and a list of chain Ids if any of the chains have the address as a Smart Contract.
 * 3. Returns 'eoa' if the address is an Externally owned account
 * */
export type AddressKind = {
  address: string;
  kind: 'eoa' | 'contract' | 'safe';
  chainIds?: (number | null)[];
};

export const getAddressKind = async (
  chains: Chain[],
  address: string,
): Promise<AddressKind> => {
  try {
    const addressIsSafeOnChains = await isSafeOnChains(chains, address);

    if (addressIsSafeOnChains.length > 0) {
      return {
        address,
        kind: 'safe',
        chainIds: addressIsSafeOnChains,
      };
    }
    const addressIsSmartContractOnChains = await isSmartContractOnChains(
      chains,
      address,
    );

    if (addressIsSmartContractOnChains.length > 0) {
      /*
       * If we later need we can narrow it more down to type of the contract, for example Account abstraction.
       * the whatsabi library has a function to follow proxies to find the final contract
       */
      return {
        address,
        kind: 'contract',
        chainIds: addressIsSmartContractOnChains,
      };
    }

    return {
      address,
      kind: 'eoa',
    };
  } catch (e) {
    throw new Error(
      (e as ValidationError)?.message || 'Unknown error getting address types',
    );
  }
};
