import React, { useEffect } from 'react';
import { createRoot, hydrateRoot, Root } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { createPath } from 'history';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'buffer';
import 'whatwg-fetch';
import queryString from 'query-string';
import history, { Action, Location } from 'utils/history';
import { updateMeta } from 'src/DOMUtils';
import { insertCss } from 'src/utils/styles';
import App from './App';
import reduxMiddleware from './middleware';
import router from './router';
import configureStore from './store/configureStore';
// eslint-disable-next-line import/no-relative-packages
import createLogger from './store/logger/logger.client';
import type { AppConfig } from './types';

let root: Root;

// Global (context) variables that can be easily accessed from any React component
// https://facebook.github.io/react/docs/context.html
const appConfig: AppConfig = {
  apiUrl: window.App.apiUrl,
  announcement: window.App.announcement,
  announcementLink: window.App.announcementLink,
  environment: window.App.environment,
  wsUrl: window.App.wsUrl,
  isSandbox: window.App.isSandbox,
  isProviderOptionForNewAccounts: window.App.isProviderOptionForNewAccounts,
  isAppsEnabled: window.App.isAppsEnabled,
  isAppPreviewEnabled: window.App.isAppPreviewEnabled,
  isMaintenance: window.App.isMaintenance,
  supportedCurrency: window.App.supportedCurrency,
  walletConnectProjectId: window.App.walletConnectProjectId,
  isInviteCodeDisabled: window.App.isInviteCodeDisabled,
  isKycRequired: window.App.isKycRequired,
  isProxy: window.App.isProxy,
  isBlockchainTxEnabled: window.App.isBlockchainTxEnabled,
  isFeatureAccountConfigToggles: window.App.isFeatureAccountConfigToggles,
  isFeatureUploadFilesToHelpThread: window.App.isFeatureUploadFilesToHelpThread,
  isFeatureCrossChainSend: window.App.isFeatureCrossChainSend,
  isFeatureFXServices: window.App.isFeatureFXServices,
  isFeatureGetNameFromID: window.App.isFeatureGetNameFromID,
  isFeatureLinkWalletSimple: window.App.isFeatureLinkWalletSimple,
  kycVerifiers: window.App.kycVerifiers,
  isPayoutSupportingDoc: window.App.isPayoutSupportingDoc,
  isFeatureFixAddressSignature: window.App.isFeatureFixAddressSignature,
  isOnfidoMotion: window.App.isOnfidoMotion,
  canPlaceGbpOrder: window.App.canPlaceGbpOrder,
  nobleChainId: window.App.nobleChainId,
};

// Initialize a new Redux store
// http://redux.js.org/docs/basics/UsageWithReact.html
const store = configureStore(
  { ...window.App.state, app: appConfig },
  createLogger,
  [reduxMiddleware],
);

const container: Element = document.getElementById('app') as Element;
let currentLocation = history?.location;

const scrollPositionsHistory: Record<
  string,
  { scrollX: number; scrollY: number }
> = {};

// Re-render the app when window.location changes
async function onLocationChange({
  location,
  action,
}: {
  location?: Location;
  action?: Action;
}) {
  // Remember the latest scroll position for the previous location
  if (currentLocation) {
    scrollPositionsHistory[currentLocation.key] = {
      scrollX: window.scrollX,
      scrollY: window.scrollY,
    };
  }
  // Delete stored scroll position for next page if any
  if (action === 'PUSH' && location?.key) {
    delete scrollPositionsHistory[location.key];
  }
  currentLocation = location;

  const isInitialRender = !action;
  try {
    const pathname = !window.App.isMaintenance
      ? location?.pathname || ''
      : '/maintenance';
    const query = queryString.parse(location?.search || '');

    // Traverses the list of routes in the order they are defined until
    // it finds the first route that matches provided URL path string
    // and whose action method returns anything other than `undefined`.
    const route = await router.resolve({ pathname, query, appConfig });

    // Prevent multiple page renders during the routing process
    if (currentLocation?.key !== location?.key) {
      return;
    }

    if (route?.redirect) {
      history?.replace(route.redirect);
      return;
    }

    const RootApp = () => {
      useEffect(() => {
        if (isInitialRender) {
          // Switch off the native scroll restoration behavior and handle it manually
          // https://developers.google.com/web/updates/2015/09/history-api-scroll-restoration
          if (window.history && 'scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
          }

          const elem = document.getElementById('css');
          if (elem) elem.parentNode?.removeChild(elem);
          return;
        }

        document.title = route?.title || '';

        updateMeta('description', route?.description || '');
        // Update necessary tags in <head> at runtime here, ie:
        // updateMeta('keywords', route.keywords);
        // updateCustomMeta('og:url', route.canonicalUrl);
        // updateCustomMeta('og:image', route.imageUrl);
        // updateLink('canonical', route.canonicalUrl);
        // etc.

        let scrollX = 0;
        let scrollY = 0;
        if (location?.key && scrollPositionsHistory[location?.key]) {
          const pos = scrollPositionsHistory[location?.key];
          scrollX = pos.scrollX;
          scrollY = pos.scrollY;
        } else {
          const targetHash = location?.hash?.substr(1);
          if (targetHash) {
            const target = document.getElementById(targetHash);
            if (target) {
              scrollY = window.pageYOffset + target.getBoundingClientRect().top;
            }
          }
        }

        // Restore the scroll position if it was saved into the state
        // or scroll to the given #hash anchor
        // or scroll to top of the page
        window.scrollTo(scrollX, scrollY);

        // Google Analytics tracking. Don't send 'pageview' event after
        // the initial rendering, as it was already sent
        if (window.ga && location) {
          window.ga('send', 'pageview', createPath(location));
        }
      });
      return (
        <ReduxProvider store={store}>
          <App
            insertCss={insertCss}
            pathname={pathname}
            query={query as Record<string, string>}
            config={appConfig}
            cookies={document.cookie}
          >
            {route?.component}
          </App>
        </ReduxProvider>
      );
    };
    if (!root && isInitialRender) {
      root = hydrateRoot(container, <RootApp />);
    } else if (root) {
      root.render(<RootApp />);
    } else {
      createRoot(container).render(<RootApp />);
    }
  } catch (error) {
    if (__DEV__) {
      throw error;
    }

    console.error(error);

    // Do a full page reload if error occurs during client-side navigation
    if (!isInitialRender && currentLocation?.key === location?.key) {
      console.error('reload your page after error');
      window.location.reload();
    }
  }
}

// Handle client-side navigation by using HTML5 History API
// For more information visit https://github.com/mjackson/history#readme
history?.listen(onLocationChange);
onLocationChange({ location: currentLocation });

// Enable Hot Module Replacement (HMR)
if (module.hot) {
  module.hot.accept('./router', () => {
    onLocationChange({ location: currentLocation });
  });
}
