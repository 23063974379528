import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import history from 'utils/history';
import { Address, isErc6492Signature, UserRejectedRequestError } from 'viem';
import {
  Connector,
  useAccount,
  useAccountEffect,
  useDisconnect,
  useReconnect,
  useSignMessage,
  useSwitchChain,
} from 'wagmi';
import accountActions from 'src/customer/components/emoney/Account/actions';
import useAccounts from 'src/customer/components/emoney/Account/hooks';
import {
  useAppDispatch,
  useAppSelector,
} from 'src/customer/store/configureStore';
import { equal } from 'src/utils/address';
import useProfile from '../../iam/Profile/hooks';
import useNotification from '../../notification/Alert/hooks';
import constants from '../Address/constants';
import useChain from '../Chain/hooks';
import { Chain } from '../Chain/types';
import { accountSelection, AddressKind, getAddressKind } from './util';

interface UseWalletContext {
  address: string;
  chain: Chain;
  connector: Connector;
  openConnectModal: (() => void) | undefined;
  selectWallet: () => Promise<void>;
  signMessage: (message: string) => Promise<`0x${string}`>;
  isSmartContract: boolean;
  addressKind: AddressKind;
  isConnected: boolean;
  isConnecting: boolean;
  isReconnecting: boolean;
  isDisconnected: boolean;
}

export const WalletContext = createContext<UseWalletContext>({
  address: '' as Address,
  chain: {} as Chain,
  connector: {} as Connector,
  openConnectModal: () => {
    /* noop */
  },
  selectWallet: async () => {
    /* noop */
  },
  signMessage: async () => '0x',
  isSmartContract: false,
  addressKind: {} as AddressKind,
  isConnected: false,
  isConnecting: false,
  isReconnecting: false,
  isDisconnected: false,
});

export const useWallet = () => useContext(WalletContext);

export const WalletProvider = ({ children }: { children: ReactNode }) => {
  const { switchChainAsync } = useSwitchChain();

  const {
    address,
    chain: connectedChain,
    status: connectionStatus,
    connector,
    isConnected,
    isConnecting,
    isReconnecting,
    isDisconnected,
  } = useAccount();

  const { selectedAccount, getAccounts, isListSuccess, isListRequest } =
    useAccounts();
  const { profileId } = useProfile();
  useEffect(() => {
    if (!isListRequest && !isListSuccess) {
      getAccounts(profileId, false);
    }
  }, [profileId]);

  const { accounts } = useAppSelector(({ emoney }) => ({
    accounts: emoney?.account.list || [],
  }));

  const { disconnectAsync } = useDisconnect();

  const dispatch = useAppDispatch();
  const [addressKind, setAddressKind] = useState<AddressKind>();
  const [isSelectingWallet, setIsReconnectingWallet] = useState(false);
  const { openConnectModal } = useConnectModal();
  const { signMessageAsync } = useSignMessage();
  const { notifyError, notifyInfo, notifyWarning } = useNotification();
  const { chains } = useChain();
  const accountsRef = useRef(accounts);
  accountsRef.current = accounts;

  const { error } = useReconnect();

  if (error) {
    notifyError(error?.message);
  }

  const checkAddressKind = async (addr) => {
    if (addr && !equal(addr, addressKind?.address) && chains.length) {
      const d = await getAddressKind(chains, addr).catch((e) =>
        notifyError(e?.message),
      );

      if (d?.chainIds?.length) {
        if (!d.chainIds.includes(connectedChain?.id as number)) {
          try {
            if (d?.kind !== 'safe') {
              await switchChainAsync({ chainId: d?.chainIds?.[0] as number });
            }
          } catch (err) {
            notifyError(err?.message);
          }
          // TODO: need to verify this.
          dispatch(
            accountSelection({
              chainUpdate: { id: d.chainIds[0] as number },
              connectedAddress: address,
              addressUpdate: addr,
              connectedChain,
              selectedAccount,
              accounts,
              chains,
            }),
          );
        }
      }

      setAddressKind(d as AddressKind);
    }
  };

  useAccountEffect({
    onConnect: ({
      isReconnected,
      address: addressUpdate,
      connector: c,
      chain: chainUpdate,
    }) => {
      if (c?.id === 'walletConnect' || c?.id === 'safe') {
        /** Check if address is a smart contract */
        checkAddressKind(addressUpdate);
      }
      // new connection, not reconnection via autoConnect
      if (!isReconnected) {
        dispatch(
          accountSelection({
            connectedAddress: address,
            addressUpdate,
            chainUpdate,
            selectedAccount,
            accounts,
            chains,
          }),
        );
      }
    },
    onDisconnect: () => {
      setAddressKind(undefined);

      if (selectedAccount) {
        dispatch(accountActions.selectAccountReset());
        if (history?.location.pathname.includes('/accounts/')) {
          history?.push(`/accounts`);
        }
      }
    },
  });

  // https://rainbowkit-example.vercel.app/

  // Allow triggering wallet selection from anywhere.
  const selectWallet = async (): Promise<void> => {
    await disconnectAsync().then(() => {
      setIsReconnectingWallet(true);
    });
  };

  /**
   * Request a signature for a specific message from the connected wallet.
   * @param message string message that shall be signed.
   * @returns a signed message or throws an error.
   */
  const signMessage = async (
    message: string,
  ): Promise<`0x${string}` | undefined> => {
    const isSafeApp = connector?.id === 'safe';
    const isSafeThroughWalletConnect =
      addressKind?.kind === 'safe' && !isSafeApp;

    notifyInfo(constants.SIGNATURE_PROMPT);

    if (isSafeThroughWalletConnect) {
      notifyWarning(
        'Stay on this page until all signatures are complete. Leaving may cancel the request.',
      );
    }
    try {
      const signature = await signMessageAsync({
        message,
      }).catch((e) => {
        notifyError(e?.message);
        throw new Error(e?.message);
      });

      if (!signature) {
        notifyError('No signature was returned by the wallet.');
        throw Error('No signature was returned by the wallet.');
      }

      if (isErc6492Signature(signature)) {
        notifyWarning(
          'Your smart wallet is in a pre-deploy state. To use our service, please deploy your wallet by making an on-chain transaction.',
        );
      }

      return signature;
    } catch (e) {
      if (
        (e as Error)?.message?.toLowerCase().includes('no signature') ||
        (e as Error)?.message?.toLowerCase().includes('request expired')
      ) {
        notifyError(
          'No signature was returned by the wallet. Please try again.',
        );
      } else if (
        e instanceof UserRejectedRequestError ||
        (e as Error)?.message?.toLowerCase().includes('rejected')
      ) {
        notifyError('Signature was rejected.');
      } else {
        notifyError('Unable to sign with the wallet because of unknown error.');
        console.error((e as Error)?.message);
      }
      return undefined;
    }
  };

  useEffect(() => {
    // For selectWallet to be able to trigger wallet selection from anywhere.
    if (isSelectingWallet) {
      if (openConnectModal !== undefined && isDisconnected) {
        openConnectModal();
      }
      setIsReconnectingWallet(false);
    }
  }, [isSelectingWallet]);

  useEffect(() => {
    const handleConnectorChange = async ({
      chainId: cId,
      accounts: addresses,
    }: {
      chainId?: number;
      accounts?: readonly `0x${string}`[];
    }) => {
      dispatch(
        accountSelection({
          addressUpdate: addresses?.[0],
          chainUpdate: chains.find((c) => c.id === cId),
          connectedAddress: address,
          connectedChain,
          selectedAccount,
          accounts,
          chains,
        }),
      );
    };

    if (connector) {
      connector?.emitter?.on('change', handleConnectorChange);
    }

    return () => {
      connector?.emitter?.off('change', handleConnectorChange);
    };
  }, [connector, address, connectedChain, accounts]);

  const contextValues = useMemo(
    () => ({
      address: address as Address,
      chain: connectedChain,
      connector: connector as Connector,
      openConnectModal,
      selectWallet,
      isSelectingWallet,
      signMessage,
      isSmartContract:
        addressKind &&
        (addressKind.kind === 'contract' || addressKind?.kind === 'safe'),
      addressKind,
      isConnected,
      isDisconnected,
      isConnecting,
      isReconnecting,
    }),
    [address, connector, addressKind, connectionStatus],
  );

  return (
    <WalletContext.Provider value={contextValues}>
      {children}
    </WalletContext.Provider>
  );
};
